import { ReactElement, ReactNode } from 'react';

import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import { match } from 'ts-pattern';

import Icon from '../Icon';
import Text from '../Text';
import { InfoCardSize, InfoCardType, TInfoCardSize, TInfoCardType } from './types';

import styles from './info-card.module.scss';

type InfoCardProps = {
  type?: TInfoCardType;
  size?: TInfoCardSize;
  small?: boolean;
  minimal?: boolean;
  smallIcon?: IconDefinition;
  className?: string;
  title?: string | ReactElement;
  icon: IconDefinition;
  text: ReactNode;
};

const InfoCard = ({
  type = InfoCardType.PRIMARY,
  size = InfoCardSize.LARGE,
  small = false,
  className,
  title,
  icon,
  minimal,
  text,
  smallIcon
}: InfoCardProps) => (
  <div
    className={cx(
      styles['info-card'],
      styles[`info-card--${type.toLowerCase()}`],

      styles[`info-card--${(small ? InfoCardSize.SMALL : size).toLowerCase()}`],
      {
        [styles[`info-card--minimal`]]: minimal,
        [styles[`info-card--small`]]: small && size === InfoCardSize.SMALL
      },
      className
    )}
  >
    {!small && (
      <div className={styles['info-card__icon']}>
        <Icon
          icon={icon}
          color="primary"
          size={match(size)
            .with(InfoCardSize.LARGE, () => 25)
            .with(InfoCardSize.SMALL, () => 20)
            .exhaustive()}
        />
      </div>
    )}
    <div className={styles['info-card__title-group']}>
      {title &&
        (small ? (
          <div className={styles['info-card__title-group--with-icon']}>
            <Icon icon={smallIcon || icon} size={14} />
            <Text type="heading3" inline>
              {title}
            </Text>
          </div>
        ) : (
          <Text type="heading2">{title}</Text>
        ))}
      <Text className={cx({ [styles['info-card--text-only']]: !title })}>{text}</Text>
    </div>
  </div>
);

export default InfoCard;
