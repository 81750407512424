import sizeMe from 'react-sizeme';

import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faBoxOpen as defaultEmptyIcon, faRobot as defaultErrorIcon } from '@fortawesome/pro-duotone-svg-icons';
import cx from 'classnames';

import { validateIconType } from '@cloud-wave/neon-common-lib/app';

import Button from 'lib/common/components/Button';
import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';

import VARS from 'css/export-vars.module.scss';
import { useLayout } from '../../contexts/layout/LayoutContext';


import './empty-placeholder.scss';

const sizeMeHOC = sizeMe({ monitorWidth: true, refreshRate: 16 });

const ICON_SIZES = {
  small: 20,
  medium: 25,
  large: 45
};

const getIcon = ({ icon, iconSize, error }) => {
  if (error) {
    return (
      <Icon
        className={cx('empty-placeholder__icon', `empty-placeholder__error-icon--${iconSize}`)}
        icon={defaultErrorIcon}
        color="primary"
        size={ICON_SIZES[iconSize]}
      />
    );
  }

  if (icon) {
    return <Icon className="empty-placeholder__icon" icon={icon} color="primary" size={ICON_SIZES[iconSize]} />;
  }

  return (
    <Icon className="empty-placeholder__icon" icon={defaultEmptyIcon} color="primary" size={ICON_SIZES[iconSize]} />
  );
};

const getIconSize = ({
  text,
  subText,
  callToAction,
  isSoftphone
}: {
  text?: string | JSX.Element;
  subText?: string | JSX.Element;
  callToAction?: {
    text: string;
    onClick: () => void;
  };
  isSoftphone?: boolean;
}) => {
  if (callToAction === void 0 || isSoftphone) {
    return 'small';
  }

  if (subText && text) {
    return 'large';
  }

  return 'medium';
};

function EmptyPlaceholder({
  size: { width },
  text,
  subText = '',
  isError: error,
  callToAction,
  useEmptyImage,
  icon,
  className
}: {
  size: { width: number };
  text?: string | JSX.Element;
  subText?: string | JSX.Element;
  isError?: boolean;
  callToAction?: {
    text: string;
    onClick: () => void;
  };
  useEmptyImage?: boolean;
  icon: IconDefinition;
  className?: string;
}) {
  validateIconType({ icon, expected: 'fad' });
  const { isSoftphone } = useLayout();
  const defaultText = error ? 'Oops, something went wrong. Try reloading the page.' : "There's nothing in here yet.";
  const mainText = text || defaultText;
  const iconSize = getIconSize({ text: mainText, subText, callToAction, isSoftphone });

  return (
    <div
      data-testId="empty-placeholder"
      className={cx(
        'empty-placeholder',
        className,
        { 'empty-placeholder--softphone': isSoftphone },
        {
          'empty-placeholder--small': width <= parseInt(VARS.softphoneWidthNumber, 10)
        }
      )}
    >
      {useEmptyImage ? null : (
        <div className={cx('empty-placeholder__icon-container', `empty-placeholder__icon-container--${iconSize}`)}>
          {getIcon({ icon, iconSize, error })}
        </div>
      )}
      <div className="empty-placeholder__content">
        <Text
          className={cx('empty-placeholder__content__message', { 'empty-placeholder__message--error': error })}
          data-testid="empty-placeholder-text"
          type={!subText ? undefined : 'heading3'}
          color="darkGrey"
        >
          {mainText}
        </Text>
        {subText && !error && (
          <Text
            className="empty-placeholder__content__message"
            data-testid="empty-placeholder-subtext"
            color="darkGrey"
          >
            {subText}
          </Text>
        )}
        {callToAction && (
          <Button
            className="empty-placeholder__content__button"
            styleType="SECONDARY"
            onClick={callToAction.onClick}
            data-testid="empty-placeholder-button"
          >
            {callToAction.text}
          </Button>
        )}
      </div>
    </div>
  );
}

export default sizeMeHOC(EmptyPlaceholder);
