import { faCircleUser } from '@fortawesome/pro-duotone-svg-icons';

import { Permissions } from '@cloud-wave/neon-common-lib';

import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';

import EmptyPlaceholder from 'lib/common/components/EmptyPlaceholder';
import * as Profile from 'lib/common/components/Profile';
import FusionData from 'lib/common/components/atoms/FusionData';

import CONTACT_TYPES from 'lib/common/constants/contactTypes';

import TTask from 'lib/common/types/Task';
import getAttachedDataContactAttributes from 'lib/common/utils/getAttachedDataContactAttributes';

import CustomerProfile from '../../../CustomerProfile';

import './customer-data.scss';

export default function CustomerData({ selectedTask }: { selectedTask?: TTask }) {
  const { hasPermission } = usePermissionsContext();

  const hasCustomerProfilePermission = hasPermission({ permission: Permissions.CUSTOMER_PROFILES });
  const hasAttachedData = Boolean(getAttachedDataContactAttributes(selectedTask?.contact, selectedTask?.type)?.length);
  const isTask = selectedTask?.type === CONTACT_TYPES.TASK;

  return (
    <div className="customer-data">
      {isTask && !hasAttachedData && <EmptyPlaceholder icon={faCircleUser} text=" " />}

      {hasAttachedData && (
        <Profile.ContentPanel title="Attached Data">
          <FusionData task={selectedTask} />
        </Profile.ContentPanel>
      )}

      {!isTask && hasAttachedData && hasCustomerProfilePermission && <hr />}

      {!isTask && hasCustomerProfilePermission && (
        <CustomerProfile profileId={selectedTask?.profile?.profileId} task={selectedTask} />
      )}
    </div>
  );
}
