import cx from 'classnames';

import { useConfigContext } from 'lib/core/config';

import Text from 'lib/common/components/Text';

import styles from './support-footer.module.scss';

const handleClick = () => connect.getLog().download();

export default function SupportFooter({ hidden = false, className }: { hidden?: boolean; className?: string }) {
  const { config } = useConfigContext();

  if (hidden) {
    return null;
  }

  return (
    <div className={cx(styles['support-footer'], className)}>
      <Text inline color="darkGrey">
        Need help? Contact your admin, check out our{' '}
        <Text inline href={config.BRAND.helpDocsUrl} className={styles['support-footer__label']}>
          help docs
        </Text>
        , or{' '}
        <Text inline onClick={handleClick} className={styles['support-footer__label']}>
          download logs
        </Text>
        .
      </Text>
    </div>
  );
}
