import { Auth } from 'aws-amplify';
import { P, match } from 'ts-pattern';

import { AuthStages, AuthState, MfaDestinations } from '../types/AuthState';

export const handleInitialChallenge =
  ({ handleAuthStep, username }: { handleAuthStep: (state: AuthState) => Promise<AuthState>; username: string }) =>
  async () => {
    const authResponse = await Auth.signIn(username);

    const { signInUserSession } = authResponse;
    if (signInUserSession) {
      return { stage: AuthStages.complete_auto };
    }

    const challengeStep = authResponse.challengeParam?.challengeName;

    const nextState = match(challengeStep)
      .with('TENANT_ID', () => ({ stage: AuthStages.tenant_id, authResponse }))
      .with('INSECURE_SIGN_IN', () => ({ stage: AuthStages.insecure, authResponse }))
      .otherwise(() => {
        throw `Unexpected challenge step: ${challengeStep}`;
      });

    return handleAuthStep(nextState);
  };

export const handleInsecureChallenge =
  ({
    handleAuthStep,
    agentConfig
  }: {
    handleAuthStep: (state: AuthState) => Promise<AuthState>;
    agentConfig: connect.AgentConfiguration;
  }) =>
  async (state: Extract<AuthState, { stage: typeof AuthStages.insecure }>) => {
    const { authResponse: lastAuthResponse } = state;
    const connectUserId = agentConfig.routingProfile.queues.filter((q) => q.name === null)[0].queueARN.split('/')[4];
    const authResponse = await Auth.sendCustomChallengeAnswer(lastAuthResponse, connectUserId);
    const challengeStep = authResponse.challengeParam?.challengeName;

    const nextState = match(challengeStep)
      .with(undefined, () => ({ stage: AuthStages.complete_insecure }))
      .otherwise(() => {
        throw `Unexpected challenge step: ${challengeStep}`;
      });

    return handleAuthStep(nextState);
  };

export const handleTenantChallenge =
  ({
    handleAuthStep,
    tenantId,
    setMfaDestinations
  }: {
    handleAuthStep: (state: AuthState) => Promise<AuthState>;
    tenantId: string;
    setMfaDestinations: (dest: { obfuscatedEmail: string | undefined; obfuscatedPhone: string | undefined }) => void;
  }) =>
  async (state: Extract<AuthState, { stage: typeof AuthStages.tenant_id }>) => {
    const { authResponse: lastAuthResponse } = state;
    const authResponse = await Auth.sendCustomChallengeAnswer(lastAuthResponse, tenantId, { tenantId });
    const challengeStep = authResponse.challengeParam?.challengeName;

    const nextState = match(challengeStep)
      .with('INSECURE_SIGN_IN', () => ({ stage: AuthStages.insecure, authResponse }))
      .with('CHOOSE_DESTINATION', () => {
        const { obfuscatedEmail, obfuscatedPhone } = lastAuthResponse.challengeParam ?? {};
        setMfaDestinations({ obfuscatedEmail, obfuscatedPhone });

        return match({ obfuscatedEmail, obfuscatedPhone })
          .with({ obfuscatedEmail: P.string.minLength(1), obfuscatedPhone: '' }, () => ({
            stage: AuthStages.mfa_selected,
            authResponse,
            obfuscatedEmail,
            mfaSelection: MfaDestinations.email
          }))
          .with({ obfuscatedEmail: '', obfuscatedPhone: P.string.minLength(1) }, () => ({
            stage: AuthStages.mfa_selected,
            authResponse,
            obfuscatedPhone,
            mfaSelection: MfaDestinations.sms
          }))
          .with({ obfuscatedEmail: P.string.minLength(1), obfuscatedPhone: P.string.minLength(1) }, () => ({
            stage: AuthStages.mfa_select,
            authResponse,
            obfuscatedEmail,
            obfuscatedPhone
          }))
          .otherwise(() => {
            throw `No MFA destinations provided`;
          });
      })
      .otherwise(() => {
        throw `Unexpected challenge step: ${challengeStep}`;
      });

    return handleAuthStep(nextState);
  };

export const handleMfaSelectionChallenge =
  ({ handleAuthStep, tenantId }: { handleAuthStep: (state: AuthState) => Promise<AuthState>; tenantId: string }) =>
  async (state: Extract<AuthState, { stage: typeof AuthStages.mfa_selected }>) => {
    const { authResponse: lastAuthResponse, mfaSelection } = state;
    const authResponse = await Auth.sendCustomChallengeAnswer(lastAuthResponse, mfaSelection, { tenantId });
    const challengeStep = authResponse.challengeParam?.challengeName;

    const nextState = match({ challengeStep, state })
      .with(
        { challengeStep: 'OTP', state: { mfaSelection: MfaDestinations.email } },
        ({ state: { obfuscatedEmail, mfaSelection } }) => ({
          stage: AuthStages.mfa_entry,
          authResponse,
          obfuscatedEmail,
          mfaSelection
        })
      )
      .with(
        { challengeStep: 'OTP', state: { mfaSelection: MfaDestinations.sms } },
        ({ state: { obfuscatedPhone, mfaSelection } }) => ({
          stage: AuthStages.mfa_entry,
          authResponse,
          obfuscatedPhone,
          mfaSelection
        })
      )
      .otherwise(() => {
        throw `Unexpected challenge step: ${challengeStep}`;
      });

    return handleAuthStep(nextState);
  };

export const handleMfaCodeChallenge =
  ({
    handleAuthStep,
    setInvalidCode,
    tenantId
  }: {
    handleAuthStep: (state: AuthState) => Promise<AuthState>;
    setInvalidCode: (invalid: boolean) => void;
    tenantId: string;
  }) =>
  async (state: Extract<AuthState, { stage: typeof AuthStages.mfa_entered }>) => {
    const { authResponse: lastAuthResponse, mfaSelection, mfaCode } = state;
    const authResponse = await Auth.sendCustomChallengeAnswer(lastAuthResponse, `${mfaSelection}:${mfaCode}`, {
      tenantId
    });
    const challengeStep = authResponse.challengeParam?.challengeName;

    const nextState = match({ challengeStep, state })
      .with(
        { challengeStep: 'OTP', state: { mfaSelection: MfaDestinations.email } },
        ({ state: { obfuscatedEmail, mfaSelection } }) => {
          setInvalidCode(true);
          return {
            stage: AuthStages.mfa_entry,
            authResponse,
            obfuscatedEmail,
            mfaSelection
          };
        }
      )
      .with(
        { challengeStep: 'OTP', state: { mfaSelection: MfaDestinations.sms } },
        ({ state: { obfuscatedPhone, mfaSelection } }) => {
          setInvalidCode(true);
          return {
            stage: AuthStages.mfa_entry,
            authResponse,
            obfuscatedPhone,
            mfaSelection
          };
        }
      )
      .with(
        { challengeStep: undefined, state: { mfaSelection: MfaDestinations.email } },
        ({ state: { obfuscatedEmail, mfaSelection } }) => ({
          stage: AuthStages.complete_mfa,
          mfaSelection,
          obfuscatedEmail
        })
      )
      .with(
        { challengeStep: undefined, state: { mfaSelection: MfaDestinations.sms } },
        ({ state: { obfuscatedPhone, mfaSelection } }) => ({
          stage: AuthStages.complete_mfa,
          mfaSelection,
          obfuscatedPhone
        })
      )
      .otherwise(() => {
        throw `Unexpected challenge step: ${challengeStep}`;
      });

    return handleAuthStep(nextState);
  };
