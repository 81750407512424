import { useConfigContext } from 'lib/core/config';

import { useAgentContext } from 'lib/common/contexts/AgentContext';

import useIsSmallSoftphone from 'lib/common/hooks/useIsSmallSoftphone';
import useIsSoftphoneQuery from 'lib/common/hooks/useIsSoftphoneQuery';

import Avatar from 'lib/common/components/Avatar';
import Button from 'lib/common/components/Button';
import { InfoCardGroup } from 'lib/common/components/InfoCard';
import SupportFooter from 'lib/common/components/SupportFooter';
import Text from 'lib/common/components/Text';

import useIsMediumScreen from 'lib/common/mediaQueries/useIsMediumScreen';
import connectGetter from 'lib/common/utils/connectGetter';

import { useAuthContext } from '../../Context';
import { AUTH_SCREEN_CONFIG } from './constants/config';

import styles from './auth-screen.module.scss';

export type AuthScreenType = 'AUTH_ERROR' | 'SIGNED_OUT' | 'DEVICE_FORGOTTEN';

const Header = () => {
  const { agent } = useAgentContext();
  const { signOut } = useAuthContext();

  const { name, username } = connectGetter(agent, 'getConfiguration') || {};

  return (
    <div className={styles['auth-screen__user']}>
      <Avatar name={name || username} className={styles['auth-screen__user__avatar']} />
      <div className={styles['auth-screen__user__info']}>
        {name && <Text type="heading2">{name}</Text>}
        {username && <Text color="darkGrey">{username}</Text>}
      </div>
      <Button onClick={() => signOut()} className={styles['auth-screen__user__sign-out']} styleType="SECONDARY">
        Sign Out
      </Button>
    </div>
  );
};

export default function AuthScreen({ type }: { type: AuthScreenType }) {
  const isSoftphone = useIsSoftphoneQuery();
  const isSmallSoftphone = useIsSmallSoftphone();
  const isMediumScreen = useIsMediumScreen();
  const { forgetDevice } = useAuthContext();
  const { config } = useConfigContext();

  const { withHeader, heading, subHeading, subtitle, helpItems, buttons, sideIcon } = AUTH_SCREEN_CONFIG(
    forgetDevice,
    isMediumScreen,
    config
  )[type];

  return (
    <>
      <div className={styles['auth-screen']}>
        {withHeader ? <Header /> : !isSoftphone && <div></div>}
        <div className={styles['auth-screen__content']}>
          <div className={styles['auth-screen__content__main']}>
            <Text type="heading1" className={styles['auth-screen__content__heading']}>
              {heading}

              {subHeading && (
                <Text type="body" color="darkGrey" className={styles['auth-screen__content__subheading']}>
                  {subHeading}
                </Text>
              )}
            </Text>
            {subtitle && <Text>{subtitle}</Text>}
            <div className={styles['auth-screen__content__left']}>
              <InfoCardGroup minimal={isSmallSoftphone} small={isSoftphone} cards={helpItems} />
            </div>
            <div className={styles['auth-screen__buttons']}>
              {buttons?.map(({ label, onClick, ...rest }, index) => (
                <Button
                  key={index}
                  styleType={rest.styleType || 'PRIMARY'}
                  onClick={onClick}
                  asyncAction={rest.asyncAction}
                >
                  {label}
                </Button>
              ))}
            </div>
          </div>
          {sideIcon}
        </div>

        <SupportFooter className={styles['auth-screen__support-footer']} />
      </div>
    </>
  );
}
