import { useMediaQuery } from 'react-responsive';

import { faPlanetRinged, faStars } from '@fortawesome/pro-duotone-svg-icons';

import Icon from 'lib/common/components/Icon';

import styles from '../auth-screen.module.scss';

export default function PlanetRingedAndStarIcon() {
  const isTablet = useMediaQuery({ query: `(max-width: 1000px)` });

  return (
    <div className={styles['auth-screen__image']}>
      <Icon icon={faPlanetRinged} color="primary" size={isTablet ? 100 : 150} />
      <Icon icon={faStars} color="primary" size={isTablet ? 30 : 50} />
    </div>
  );
}
