import { ReactNode, useEffect, useRef, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';

import { setContext, setUser as setSentryUser } from '@sentry/browser';
import { Amplify, Auth } from 'aws-amplify';
import { match } from 'ts-pattern';

import { useConfigContext } from 'lib/core/config';
import { ValueOf } from 'lib/core/types';

import { useAgentContext } from 'lib/common/contexts/AgentContext';

import { useLocalStorage } from 'lib/common/hooks/useLocalStorage';

import Loader from 'lib/common/components/Loader';
import { LogEvents, logger } from 'lib/common/components/LoggerController';

import SIGN_OUT_EVENT from 'lib/common/constants/signOutEvent';

import type TUser from 'lib/common/types/User';
import connectGetter from 'lib/common/utils/connectGetter';

import { useStreamsContext } from '../StreamsProvider';
import Context from './Context';
import AuthScreen from './components/AuthScreen';
import LoginPage from './components/Login';
import SigningOutOverlay from './components/SigningOutOverlay';
import SIGN_OUT_TYPES from './constants/signOutTypes';
import { useAuthFlow } from './hooks/useAuthFlow';
import { handleSignout } from './utils/handleSignout';

type Props = {
  children: ReactNode;
};

const USERNAME_SUFFIX = '@neon.com';

function getConnectUserID(agent: connect.Agent) {
  const agentQueues = connectGetter(agent, 'getConfiguration')?.routingProfile.queues;

  // By default, an agent is assigned a queue for transferring calls to the agent directly.
  const agentQueueArn = agentQueues?.find((q) => q.name === null)?.queueARN;

  // We get the connect user ID from the null queue ARN:
  // e.g. arn:aws:connect:ap-southeast-2:account:instance/instance-id/queue/agent/6ce04498-e7a3-44c2-a807-19a882bd6577"
  return agentQueueArn?.split('/')[4];
}

const AuthProvider = ({ children }: Props) => {
  const { config } = useConfigContext();
  const { agent, agentConfig } = useAgentContext();
  const { failed: ccpFailed } = useStreamsContext();

  const [email, setEmail] = useState<string>('');
  const [user, setUser] = useState<TUser | null>(null);
  const [connectUserId, setConnectUserId] = useState<string | undefined>();

  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [signedOut, setSignedOut] = useState<boolean>(false);
  const [deviceForgotten, setDeviceForgotten] = useState<boolean>(false);
  const [signingOut, setSigningOut] = useState<boolean>(false);

  // Allows callbacks to get the latest user to avoid stale references, eg. in connect handlers
  const userRef = useRef(user);
  const getUser = () => userRef?.current;

  const signingOutRef = useRef<null | ValueOf<typeof SIGN_OUT_TYPES>>(null);

  const { removeStorageItem, setStorageItem } = useLocalStorage();

  const tenantId = config.TENANT_ID;

  const { authStage, invalidCode, mfaDestinations, mfaSelection, beginAuth, selectMfaDestination, provideMfaCode } =
    useAuthFlow({
      tenantId,
      agentConfig: agentConfig!
    });

  const fetch_ = async (url: string, options: RequestInit = {}): Promise<Response> => {
    const currentSession = await Auth.currentSession().catch((error) => {
      logger.warn(LogEvents.AUTH.REFRESH_TOKEN_FAIL, { error });
      setSignedOut(true);
    });
    const token = currentSession?.getIdToken().getJwtToken();

    if (!token) {
      logger.warn(LogEvents.AUTH.REFRESH_TOKEN_FAIL);
      setSignedOut(true);
      throw 'Unauthorized';
    }

    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    const result = await fetch(url, { ...options, headers });

    if (!result.ok) {
      const errorBody = await result?.json?.();

      throw { ...errorBody, status: result.status };
    }

    return result;
  };

  const signIn = async () => {
    try {
      await beginAuth();

      logger.info(LogEvents.AUTH.SIGN_IN.SUCCESS, { username: '' });
    } catch (error) {
      logger.info(LogEvents.AUTH.SIGN_IN.FAIL, { username: '', error });
      setError(true);
    }
  };

  const forgetDevice = async () => {
    await handleSignout({ global: true });

    sessionStorage.clear();

    // Cross tab method to trigger sign out across other tabs in isolated mode
    // Other instances of neon will auto sign out because they have Connect embedded
    setStorageItem(SIGN_OUT_EVENT, 'true');

    setDeviceForgotten(true);

    logger.info(LogEvents.AUTH.FORGET_DEVICE.SUCCESS);
  };

  const signOut = async (type = SIGN_OUT_TYPES.MANUAL_SIGN_OUT) => {
    if (signingOutRef?.current) {
      return;
    }

    logger.info(LogEvents.AUTH.SIGN_OUT.INITIATED, { user: user?.email });

    signingOutRef.current = type;
    setSigningOut(true);

    try {
      await fetch(`${config.CONNECT_HOST}/connect/logout`, {
        credentials: 'include',
        mode: 'no-cors'
      });
    } catch (error) {
      logger.error(LogEvents.AUTH.SIGN_OUT.COMPLETED.FAIL, { error });
      return;
    }

    connect.core.terminate();

    fetch_(`${config.AGENT_SERVICE_URL}/agent/${config.TENANT_ID}__${sessionStorage.getItem('c_user')}/cleanup/`, {
      body: 'close',
      method: 'POST'
    });

    logger.info(LogEvents.AUTH.SIGN_OUT.COMPLETED.SUCCESS, { user: user?.email });

    setSigningOut(false);

    // Don't reload the page if it's a manual sign out
    if (type === SIGN_OUT_TYPES.MANUAL_SIGN_OUT) {
      return void setSignedOut(true);
    }

    window.location.href = window.location.origin;
  };

  const initializeUser = async () => {
    const currentUser = await Auth.currentAuthenticatedUser();

    // tenantId__username (non sso) || tenantId__username@company.com (sso)
    const usernameWithTenantId = currentUser.username.includes(USERNAME_SUFFIX)
      ? currentUser.username.split('@')[0]
      : currentUser.username;

    const userId = usernameWithTenantId.split('__')[1];

    const objectKey = `${config.TENANT_ID}__${userId}`;
    const agentUrl = `${config.AGENT_SERVICE_URL}/agent/${objectKey}`;

    try {
      const response = await fetch_(agentUrl);
      const data = await response.json();

      const userToStore = { ...data, username: userId };

      setUser(userToStore);

      sessionStorage.setItem('email', userToStore.email || '');
      logger.info(LogEvents.AUTH.INITIALIZE_USER.SUCCESS);
    } catch (error) {
      logger.info(LogEvents.AUTH.INITIALIZE_USER.FAIL, { error });
      setError(true);
    }
  };

  const initializeApp = async () => {
    // If the user has previously signed out and this event exists in storage, remove it on sign in
    removeStorageItem(SIGN_OUT_EVENT);

    try {
      const userInfoJson = await fetch_(
        `${config.AGENT_SERVICE_URL}/connect/${tenantId}/describe/user/?objectId=${connectUserId}`
      );
      const userInfo = await userInfoJson.json();

      // Making userInfo optional as IsolatedAuthProvider user object can be empty
      setEmail(userInfo?.User?.IdentityInfo?.Email);

      // Logging users out after session invalid
      // @ts-expect-error This exists and works but isn't in the type
      connect.core.getEventBus().subscribe(connect.EventType.AUTH_FAIL, () => {
        logger.warn(LogEvents.AUTH.SESSION_EXPIRED, { user: user?.email });

        signOut(SIGN_OUT_TYPES.AUTH_FAIL);
      });

      setLoaded(true);
      logger.info(LogEvents.AUTH.INITIALIZE_APP.SUCCESS);
    } catch (error) {
      logger.info(LogEvents.AUTH.INITIALIZE_APP.FAIL, { error });
      setError(true);
    }
  };

  useEffect(() => {
    Amplify.configure({
      Auth: {
        region: config.COGNITO_USER_POOL_ARN.split(':')[3],
        userPoolId: config.COGNITO_USER_POOL_ARN.split('/')[1],
        userPoolWebClientId: config.COGNITO_CLIENT_ID
      }
    });
  }, []);

  useEffect(() => {
    if (!agent || loaded) {
      return;
    }

    if (!agentConfig) {
      logger.error(LogEvents.AUTH.AGENT_CONFIG.FAIL);
      setError(true);
      return;
    }

    setConnectUserId(getConnectUserID(agent));
    sessionStorage.setItem('c_user', agentConfig.username);

    signIn();

    // Set Sentry contexts for reporting
    setContext('user', agentConfig);
    setSentryUser({ username: agentConfig.username });
  }, [agent]);

  useEffect(() => {
    if (authStage.includes('complete')) {
      initializeUser();
    }
  }, [authStage]);

  useEffect(() => {
    if (!user || !connectUserId || loaded) {
      return;
    }

    initializeApp();
  }, [user, connectUserId]);

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  useBeforeunload((event) => {
    const contacts = connectGetter(agent, 'getContacts') || [];

    // If there are no contacts, or we are signing out, don't block reload
    if (!contacts.length || signingOutRef.current) {
      return;
    }

    // Show dialog to prevent users from refreshing the page when they have tasks
    logger.warn(LogEvents.PAGE_RELOAD_WITH_TASKS, { contacts });
    event.preventDefault();
  });

  return (
    <Context.Provider
      value={{
        fetch_,
        loaded,
        email,
        signOut,
        connectUserId,
        user,
        getUser,
        forgetDevice
      }}
    >
      <>
        {signingOut && signingOutRef?.current && <SigningOutOverlay type={signingOutRef?.current} />}
        {match({ error, ccpFailed, signedOut, user: !!user, loaded, deviceForgotten })
          .with({ deviceForgotten: true }, () => <AuthScreen type="DEVICE_FORGOTTEN" />)
          .with({ signedOut: true }, () => <AuthScreen type="SIGNED_OUT" />)
          .with({ error: true }, { ccpFailed: true }, () => <AuthScreen type="AUTH_ERROR" />)
          .with({ user: false }, () => (
            <LoginPage
              authStage={authStage}
              mfaDestinations={mfaDestinations}
              mfaSelection={mfaSelection}
              invalidCode={invalidCode}
              signOut={signOut}
              selectMfaDestination={selectMfaDestination}
              provideMfaCode={provideMfaCode}
            />
          ))
          .with({ user: true, loaded: false }, () => <Loader />)
          .with({ loaded: true }, () => children)
          .exhaustive()}
      </>
    </Context.Provider>
  );
};

export default AuthProvider;
