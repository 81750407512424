import COLOURS from 'css/export-vars.module.scss';

export default {
  primary: COLOURS.primary,
  black: COLOURS.black,
  danger: COLOURS.danger,
  text: COLOURS.text,
  darkBlue: COLOURS.darkBlue,
  blue: COLOURS.blue,
  white: COLOURS.white,
  darkGrey: COLOURS.darkGrey,
  success: COLOURS.success,
  warning: COLOURS.warning
};
