import { createContext, useContext } from 'react';

import TUser from 'lib/common/types/User';

export type IFetch = (url, options?) => Promise<Response>;

export type TAuthContext = {
  fetch_: IFetch;
  loaded?: boolean;
  email?: string;
  signOut: () => void;
  forgetDevice: () => void;
  connectUserId?: string;
  user: TUser | null;
  getUser: () => TUser | null;
};

const defaultAuth = {
  fetch_: () => Promise.resolve(new Response()),
  signOut: () => {},
  forgetDevice: () => {},
  user: null,
  getUser: () => null
};

const context = createContext<TAuthContext>(defaultAuth);

export const useAuthContext = () => useContext(context);

export default context;
