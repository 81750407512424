import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { faAddressCard } from '@fortawesome/pro-duotone-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useBooleanState } from 'webrix/hooks';

import { Permissions } from '@cloud-wave/neon-common-lib';

import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';

import Button from 'lib/common/components/Button';
import EmptyPlaceholder from 'lib/common/components/EmptyPlaceholder';
import Loader from 'lib/common/components/Loader';
import RequiresPermission from 'lib/common/components/RequiresPermission';

import searchCustomerProfiles from 'lib/common/api/customerProfiles/searchCustomerProfiles';
import TProfile from 'lib/common/types/CustomerProfile';

import ProfilesTable from './ProfilesTable';
import Search from './Search';

import '../styles/customer-profiles.scss';

const MAX_FETCHED_PROFILES = 30;

export default function CustomerProfiles() {
  const { value: searching, setTrue: setSearching, setFalse: setNotSearching } = useBooleanState();
  const { fetch_ } = useAuthContext();
  const { config } = useConfigContext();

  const [profiles, setProfiles] = useState<null | TProfile[]>(null);

  const history = useHistory();

  const onSearch = async (value: string, type: string) => {
    if (!value) {
      return void setProfiles(null);
    }

    setSearching();

    setProfiles(
      await searchCustomerProfiles({
        config,
        fetch_,
        type,
        value,
        maxResults: MAX_FETCHED_PROFILES
      })
    );

    setNotSearching();
  };

  const onClickCreateProfile = () => {
    history.push('/profiles/new');
  };

  const clearResults = () => {
    setProfiles(null);
  };

  return (
    <>
      <div className="flex mb-40">
        <Search onSearch={onSearch} searching={searching} clearResults={clearResults} />
        <RequiresPermission permission={Permissions.CUSTOMER_PROFILES_WRITE}>
          <Button ariaLabel="Create profile" className="ml-40" icon={faPlus} onClick={onClickCreateProfile} />
        </RequiresPermission>
      </div>
      <div className="panel customer-profiles__results">
        {!profiles && !searching && (
          <EmptyPlaceholder
            icon={faAddressCard}
            text="Search for a profile by using their full name, phone number, account number or email address."
          />
        )}
        {profiles && !searching && <ProfilesTable profiles={profiles} />}
        {searching && <Loader relative small />}
      </div>
    </>
  );
}
