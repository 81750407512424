import { createContext, useContext } from 'react';

const LayoutContext = createContext<{ isSoftphone: boolean; isMediumScreen: boolean }>({
  isSoftphone: false,
  isMediumScreen: false
});

export const useLayout = () => useContext(LayoutContext);

export default LayoutContext;
