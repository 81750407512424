import 'what-input';

import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';

import ContactContext from 'lib/common/contexts/ContactContext';
// contexts
import PermissionsContext from 'lib/common/contexts/PermissionsContext';

import Loader from 'lib/common/components/Loader';

// components
import getQueryParam from 'lib/common/utils/getQueryParam';

import LayoutSwitcher from './components/LayoutSwitcher';

const REVERSED_APP_LAYOUT_QUERY_PARAM = 'reversed';

function Routes() {
  const { user } = useAuthContext();
  const { config } = useConfigContext();

  // Define this in here, so we can capture it before any routes changes or any redirects occur
  const isReversedLayout = Boolean(getQueryParam({ param: REVERSED_APP_LAYOUT_QUERY_PARAM }));

  if (!user) {
    return <Loader />;
  }

  return (
    <PermissionsContext user={user} config={config}>
      <ContactContext>
        <LayoutSwitcher isReversedLayout={isReversedLayout} user={user} />
      </ContactContext>
    </PermissionsContext>
  );
}

export default Routes;
