import { ReactNode } from 'react';

import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import { match } from 'ts-pattern';

import EmptyPlaceholder from 'lib/common/components/EmptyPlaceholder';
import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';

import './content-panel.scss';

type TContentPanel = {
  children: any;
  title: string;
  subtitle?: string;
  icon?: IconDefinition;
  alwaysShowChildren?: boolean;
  className?: string;
  actions?: ReactNode;
};

type TContentPanelEmpty = {
  isEmpty?: boolean;
  emptyMessage: string;
  emptyIcon: IconDefinition;
} & TContentPanel;

type TContentParams = TContentPanel | TContentPanelEmpty;

export default function ContentPanel(props: TContentParams) {
  //for some reason the ts match breaks some tests so have to use ts ignore
  // @ts-expect-error
  const { title, subtitle, icon, className, actions, isEmpty, emptyMessage, emptyIcon, alwaysShowChildren, children } =
    props;

  return (
    <div className={cx('content-panel', className)}>
      <div className="content-panel__header">
        <div className="content-panel__header__title">
          <Text type="heading2" htmlElement="h3">
            {title} {icon && <Icon className="ml-10" icon={icon} />}
          </Text>
          {actions}
        </div>
        {subtitle && (
          <Text type="body" className="content-panel__subtitle">
            {subtitle}
          </Text>
        )}
      </div>
      {isEmpty && (emptyIcon || emptyMessage) && (
        <EmptyPlaceholder className="mt-30 mb-30" icon={emptyIcon} text={emptyMessage} />
      )}
      {(!isEmpty || (isEmpty && alwaysShowChildren)) && children}
    </div>
  );
}
