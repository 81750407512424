import { ReactNode, useRef, useState } from 'react';

import { useConfigContext } from 'lib/core/config';

import { usePreferencesContext } from 'lib/common/contexts/PreferencesContext';

import { LogEvents, logger } from 'lib/common/components/LoggerController';

import { NONE as NO_RINGTONE } from 'lib/common/constants/ringtoneOptions';

import getInstanceUrl from 'lib/common/utils/getInstanceUrl';

import { Context } from '.';

const DEFAULT_CCP_TIMEOUT_MS = 5000;
const LOG_LEVEL = 'ERROR';

export default function ConnectStreams({ children }: { children: ReactNode }) {
  const {
    state: { ringtone }
  } = usePreferencesContext();
  const { config } = useConfigContext();

  const [initialized, setInitialized] = useState(false);
  const [failed, setFailed] = useState(false);

  const ccpEl = useRef(null);
  const instanceUrl = getInstanceUrl(config);

  const ccpURL = `${instanceUrl}/ccp-v2`;
  const ccpLoginURL = config.SSO_URL || `${instanceUrl}/login`;

  const initializeCCP = async () => {
    if (!ccpEl || !ccpEl.current) {
      return;
    }

    try {
      // This is used to ignore all [INFO] messages coming from CCP.
      // @ts-ignore
      connect.getLog().setEchoLevel(LOG_LEVEL);

      connect.core.initCCP(ccpEl.current, {
        ccpUrl: ccpURL,
        loginPopup: true,
        loginUrl: ccpLoginURL,
        softphone: {
          allowFramedSoftphone: true,
          disableRingtone: ringtone === NO_RINGTONE,
          ringtoneUrl: ringtone
        },
        task: {
          disableRingtone: ringtone === NO_RINGTONE,
          ringtoneUrl: ringtone
        },
        chat: {
          disableRingtone: ringtone === NO_RINGTONE,
          ringtoneUrl: ringtone
        },
        loginOptions: {
          autoClose: true,
          top: 0,
          left: 0,
          height: 472.867,
          width: 355
        },
        loginPopupAutoClose: true,
        region: config.CONNECT_REGION,
        ccpAckTimeout: DEFAULT_CCP_TIMEOUT_MS,
        ccpSynTimeout: DEFAULT_CCP_TIMEOUT_MS,
        ccpLoadTimeout: DEFAULT_CCP_TIMEOUT_MS
      });
    } catch (e) {
      logger.error(LogEvents.STREAMS_INIT.FAIL);
      setFailed(true);
      throw e;
    }

    return new Promise<void>((resolve) => {
      connect.core.onInitialized(() => {
        logger.info(LogEvents.STREAMS_INIT.SUCCESS);
        setInitialized(true);
        resolve();
      });
    });
  };

  return (
    <Context.Provider value={{ initializeCCP, initialized, failed }}>
      <div style={{ display: 'none' }} ref={ccpEl} />
      {children}
    </Context.Provider>
  );
}
