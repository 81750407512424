import { useRef, useState } from 'react';
import ReactCodeInput from 'react-code-input';

import { faArrowRight, faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import { match } from 'ts-pattern';

import useIsSoftphoneQuery from 'lib/common/hooks/useIsSoftphoneQuery';
import useTrigger from 'lib/common/hooks/useTrigger';

import Badge from 'lib/common/components/Badge';
import Button from 'lib/common/components/Button';
import Text from 'lib/common/components/Text';

import useIsMediumScreen from 'lib/common/mediaQueries/useIsMediumScreen';

import { MfaDestination, MfaDestinations } from '../../../types/AuthState';
import { reactCodeInputStyle, reactCodeInvalidStyle } from './EnterMFA.styles';
import SelectMFA from './SelectMFA';

import styles from './enter-mfa.module.scss';

const Main = ({
  invalidCode,
  mfaSelection,
  mfaDestinations,
  provideMfaCode,
  openInfo
}: {
  invalidCode: boolean;
  mfaSelection: MfaDestination | undefined;
  mfaDestinations: { obfuscatedEmail: string | undefined; obfuscatedPhone: string | undefined };
  provideMfaCode: (code: string) => Promise<void>;
  openInfo: () => void;
}) => {
  const isSoftphone = useIsSoftphoneQuery();
  const isMediumScreen = useIsMediumScreen();

  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [trigger, changeTrigger] = useTrigger();

  // ReactCodeInput ref is a custom type that is not provided
  const inputRef = useRef<any>(null);

  return (
    <div className={cx(styles['enter-mfa'], { [styles['enter-mfa--softphone']]: isSoftphone })}>
      <Text type="heading1">Just Confirming It's You</Text>
      <Text>
        We've{' '}
        {match(mfaSelection)
          .with(MfaDestinations.email, () => 'emailed')
          .with(MfaDestinations.sms, () => 'sent')
          .with(undefined, () => 'sent')
          .exhaustive()}{' '}
        a verification code to{' '}
        {mfaSelection ? (
          <Badge
            type="SECONDARY"
            label={match(mfaSelection)
              .with(MfaDestinations.email, () => mfaDestinations.obfuscatedEmail)
              .with(MfaDestinations.sms, () => mfaDestinations.obfuscatedPhone)
              .exhaustive()}
          />
        ) : (
          'your device'
        )}
        . Can't find the {'email'}? Check your spam folder or{' '}
        <Text inline type="link" onClick={() => {}}>
          resend
        </Text>
        .
      </Text>

      <form
        style={{ display: 'contents' }}
        onSubmit={async (e) => {
          e.preventDefault();

          setLoading(true);

          try {
            await provideMfaCode(code);
          } catch {
            setCode('');
            setLoading(false);

            // horrible unforgivable jank but the best we can do with this code input component
            inputRef?.current?.textInput?.[0]?.focus?.();
            inputRef.current.state.input[0] = '';
            inputRef.current.state.input[1] = '';
            inputRef.current.state.input[2] = '';
            inputRef.current.state.input[3] = '';
            inputRef.current.state.input[4] = '';
            inputRef.current.state.input[5] = '';
            changeTrigger();
          }
        }}
      >
        <ReactCodeInput
          ref={inputRef}
          value={code}
          key={`codeInputInvalid_${trigger}`}
          disabled={loading}
          isValid={!invalidCode}
          name="verification-code"
          inputMode="numeric"
          className={styles['enter-mfa__code-input']}
          onChange={(value) => setCode(value)}
          fields={6}
          inputStyle={{ ...reactCodeInputStyle(isSoftphone) }}
          inputStyleInvalid={{ ...reactCodeInputStyle(isSoftphone), ...reactCodeInvalidStyle }}
        />

        {invalidCode && <Text color="danger">That code doesn't look quite right, give it another go.</Text>}

        <div className={styles['enter-mfa__buttons']}>
          {(isSoftphone || isMediumScreen) && (
            <Button styleType="SECONDARY" icon={faCircleQuestion} onClick={openInfo}>
              How It Works
            </Button>
          )}
          <Button busy={loading} type="submit" icon={faArrowRight} disabled={code.length != 6}>
            Continue
          </Button>
        </div>
      </form>
    </div>
  );
};
const Info = SelectMFA.Info;

export default { Main, Info };
