import { CognitoUser as BaseCognitoUser } from 'amazon-cognito-identity-js';

import { ConstValues, createEnum } from '@cloud-wave/neon-common-lib';

interface CognitoUser extends BaseCognitoUser {
  challengeParam?: Record<string, string>;
}

export const AuthStages = createEnum(
  'initial',
  'tenant_id',
  'insecure',
  'mfa_select',
  'mfa_selected',
  'mfa_entry',
  'mfa_entered',
  'complete_mfa',
  'complete_auto',
  'complete_insecure'
);
export type AuthStage = ConstValues<typeof AuthStages>;

export const MfaDestinations = createEnum('email', 'sms');
export type MfaDestination = ConstValues<typeof MfaDestinations>;

export type AuthState =
  | { stage: typeof AuthStages.initial }
  | { stage: typeof AuthStages.tenant_id; authResponse: CognitoUser }
  | { stage: typeof AuthStages.insecure; authResponse: CognitoUser }
  | {
      stage: typeof AuthStages.mfa_select;
      authResponse: CognitoUser;
      obfuscatedEmail: string;
      obfuscatedPhone: string;
    }
  | {
      stage: typeof AuthStages.mfa_selected;
      authResponse: CognitoUser;
      mfaSelection: typeof MfaDestinations.email;
      obfuscatedEmail: string;
    }
  | {
      stage: typeof AuthStages.mfa_selected;
      authResponse: CognitoUser;
      mfaSelection: typeof MfaDestinations.sms;
      obfuscatedPhone: string;
    }
  | {
      stage: typeof AuthStages.mfa_entry;
      authResponse: CognitoUser;
      mfaSelection: typeof MfaDestinations.email;
      obfuscatedEmail: string;
    }
  | {
      stage: typeof AuthStages.mfa_entry;
      authResponse: CognitoUser;
      mfaSelection: typeof MfaDestinations.sms;
      obfuscatedPhone: string;
    }
  | {
      stage: typeof AuthStages.mfa_entered;
      authResponse: CognitoUser;
      mfaSelection: typeof MfaDestinations.email;
      obfuscatedEmail: string;
      mfaCode: string;
    }
  | {
      stage: typeof AuthStages.mfa_entered;
      authResponse: CognitoUser;
      mfaSelection: typeof MfaDestinations.sms;
      obfuscatedPhone: string;
      mfaCode: string;
    }
  | { stage: typeof AuthStages.complete_mfa; mfaSelection: typeof MfaDestinations.email; obfuscatedEmail: string }
  | { stage: typeof AuthStages.complete_mfa; mfaSelection: typeof MfaDestinations.sms; obfuscatedPhone: string }
  | { stage: typeof AuthStages.complete_auto }
  | { stage: typeof AuthStages.complete_insecure };
