import { createContext, useContext } from 'react';

type AgentContextType = {
  agent: connect.Agent | null;
  agentConfig: connect.AgentConfiguration | undefined | null;
  states: connect.AgentStateDefinition[];
  routingProfile?: string;
  channelConcurrencyMap?: connect.AgentChannelConcurrencyMap;
};

const Context = createContext<AgentContextType>({
  agent: null,
  agentConfig: null,
  states: []
});

// export the context hook
export const useAgentContext = () => useContext(Context);

export default Context;
