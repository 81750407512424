import { useEffect, useRef, useState } from 'react';

import { faPlugCircleExclamation } from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpRightFromSquare, faInfoCircle, faSync, faTimer } from '@fortawesome/pro-regular-svg-icons';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import cx from 'classnames';
import { format } from 'date-fns';

import { ValueOf } from 'lib/core/types';

import useInterval from 'lib/common/hooks/useInterval';
import useIsolatedNavigate from 'lib/common/hooks/useIsolatedNavigate';
import { useLocalStorage } from 'lib/common/hooks/useLocalStorage';

import Badge from 'lib/common/components/Badge';
import ClickableIcon from 'lib/common/components/ClickableIcon';
import Error from 'lib/common/components/Error';
import Icon from 'lib/common/components/Icon';
import Loader from 'lib/common/components/Loader';
import { LogEvents, logger } from 'lib/common/components/LoggerController';
import Text from 'lib/common/components/Text';

import REPORT_TYPES from 'lib/common/constants/reportTypes';

import isIsolatedMode from 'lib/common/utils/isIsolatedMode';

import { TConfig } from '../../../common/types/Config';
import getQuicksightDashboardUrl from './api/getQuicksighDashboardUrl';

import styles from './report.module.scss';

interface IQSDashboard {
  on: (event: string, cb: (any) => any) => void;
  setParameters: ({}: { [key: string]: string }) => void;
}

interface IReport {
  type: ValueOf<typeof REPORT_TYPES>;
  fetch: (url: string, options: any) => Promise<any>;
  config: TConfig;
}

const ERROR_TIMEOUT_MS = 30000;
const REFRESH_POLL_MS = 15000;

const SECURE_PROTOCOL = 'https:';

const TITLES = {
  [REPORT_TYPES.ANALYTICS]: 'Analytics',
  [REPORT_TYPES.PERFORMANCE_TODAY]: 'Performance Today',
  [REPORT_TYPES.WALLBOARD]: 'Wallboard'
};

// This has to be defined outside our React function because the QS JS ends displaying two dashboards
let quicksightDashboardRef: IQSDashboard | null;

export default function Report({ type, fetch, config }: IReport) {
  const [uniqueContainerId] = useState(`quicksight-report-container-${Date.now()}`);
  const [error, setError] = useState(false);
  const [refreshedAt, setRefreshedAt] = useState(Date.now());
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isQuickSightNotFoundError, setIsQuickSightNotFoundError] = useState(false);

  const { getStorageItem, setStorageItem } = useLocalStorage();

  function getSavedParamsFromStorage(reportId: string) {
    const stored = getStorageItem(reportId);

    if (!stored) {
      return {};
    }

    try {
      return JSON.parse(stored);
    } catch (e) {
      return {};
    }
  }

  // Use a ref instead of state because QS uses callbacks and state variables become stale
  const loadedRef = useRef(false);
  const loaded = loadedRef?.current;

  const isolatedNavigate = useIsolatedNavigate();

  const reportId = config.REPORT_IDS[type];

  const [currentParameters, setParameters] = useState(getSavedParamsFromStorage(reportId));

  const isIsolated = isIsolatedMode();

  const onLoad = () => {
    loadedRef.current = true;

    setRefreshedAt(Date.now());

    if (!currentParameters) {
      return;
    }

    try {
      quicksightDashboardRef?.setParameters(currentParameters);
    } catch {}
  };

  const onRefresh = () => {
    if (!quicksightDashboardRef) {
      return;
    }

    setIsRefreshing(true);

    // We just need the value of the parameter to change to trigger a data refresh. Can use a simple math random here
    // to trigger this
    try {
      quicksightDashboardRef?.setParameters({ ...currentParameters, triggerRefresh: Math.random() });
    } catch {}

    setTimeout(() => {
      setIsRefreshing(false);
      setRefreshedAt(Date.now());
    }, 2000);
  };

  const onChangeParameters = ({ parameters }) => {
    // Use the ref directly here, because the value of loaded is stale
    if (!loadedRef.current) {
      return;
    }

    const settableParams = parameters.reduce(
      (allParams, param) => ({
        ...allParams,
        [param.name]: param.value
      }),
      {}
    );

    setParameters(settableParams);

    setStorageItem(reportId, JSON.stringify(settableParams));
  };

  useEffect(() => {
    let errorTimeout;

    (async () => {
      try {
        const dashboardUrl = await getQuicksightDashboardUrl({ config, reportId, fetch });

        const options = {
          url: dashboardUrl,
          container: `#${uniqueContainerId}`,
          printEnabled: true,
          undoRedoDisabled: true,
          resetDisabled: true,
          loadCallback: onLoad,
          errorCallback: (error) => {
            logger.error(LogEvents.REPORT_LOAD_FAILED, { error });
            setError(true);
          },
          parametersChangeCallback: onChangeParameters
        };

        try {
          quicksightDashboardRef = embedDashboard(options);
        } catch {}

        errorTimeout = setTimeout(() => {
          setError(true);
        }, ERROR_TIMEOUT_MS);
      } catch (error: any) {
        if (error?.status === 404) {
          setError(true);
          setIsQuickSightNotFoundError(true);
        }

        return void setError(true);
      }
    })();

    return () => {
      clearTimeout(errorTimeout);

      quicksightDashboardRef = null;

      const frame = document.getElementById(uniqueContainerId);

      if (!frame) {
        return;
      }

      frame.innerHTML = '';
    };
  }, []);

  useInterval(() => {
    if (type !== REPORT_TYPES.WALLBOARD || !loadedRef.current) {
      return;
    }

    onRefresh();
  }, REFRESH_POLL_MS);

  if (window.location.protocol !== SECURE_PROTOCOL) {
    return <Text color="danger">The report can only be displayed when running the app securely.</Text>;
  }

  const title = TITLES[type];

  return (
    <div className={styles['report']}>
      {!loaded && !error && <Loader />}
      <div className={styles['report__header']} data-testid="report-header">
        {title && (
          <div className={styles['report__header__title']}>
            <Text type="heading1">{type === REPORT_TYPES.WALLBOARD ? title : 'Reports'}</Text>
            {type !== REPORT_TYPES.WALLBOARD && (
              <Badge
                className={cx(styles['report__header__title__badge'], 'ml-20')}
                label={title}
                type="DEFAULT"
                bold
              />
            )}
            {!isIsolated && (
              <ClickableIcon
                className="ml-20"
                icon={faArrowUpRightFromSquare}
                tooltip={`Open this report in a new standalone ${config.BRAND.productName} page`}
                onClick={() => {
                  isolatedNavigate();
                }}
              />
            )}
          </div>
        )}
        {loaded && (
          <>
            {type === REPORT_TYPES.WALLBOARD && (
              <Icon
                className="mr-10 ml-auto"
                icon={faTimer}
                size={15}
                color="success"
                tooltip="We're automatically refreshing the data in this report every 15s"
              />
            )}
            <Text className={cx('mr-10', { 'ml-auto': type !== REPORT_TYPES.WALLBOARD })}>
              Last refreshed at <strong>{format(refreshedAt, 'HH:mm:ss')}</strong>
            </Text>
            <Icon
              icon={faInfoCircle}
              tooltip="The last time the dashboard visualisations were refreshed. Reports are synchronised with your contact centre automatically, which means this might not be the time the data was actually updated."
              size={15}
              color="darkGrey"
            />
            <ClickableIcon
              className="ml-30"
              icon={faSync}
              onClick={onRefresh}
              disabled={isRefreshing}
              tooltip="Manually refresh the report"
            />
          </>
        )}
      </div>
      {!loaded && error && (
        <Error
          relative
          hideFooter
          hidePrimaryAction={isQuickSightNotFoundError}
          icon={isQuickSightNotFoundError ? faPlugCircleExclamation : undefined}
          text={
            isQuickSightNotFoundError
              ? 'It looks like something hasn’t been set up correctly. Try getting in touch with your Administrator.'
              : 'Something went wrong loading this report. Try reloading the page.'
          }
        />
      )}
      {error && !loaded ? null : (
        <div
          id={uniqueContainerId}
          className={styles['report__container']}
          style={{ opacity: loaded ? 1 : 0 }}
          data-testid="quicksight-container"
        />
      )}
    </div>
  );
}
