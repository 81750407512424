import { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faRobot } from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import { P, match } from 'ts-pattern';

import { createEnum } from '@cloud-wave/neon-common-lib';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import useIsSoftphoneQuery from 'lib/common/hooks/useIsSoftphoneQuery';

import Button from 'lib/common/components/Button';
import Icon from 'lib/common/components/Icon';
import { IconSizeConstraints } from 'lib/common/components/Icon/Icon';
import Text from 'lib/common/components/Text';

import useIsMediumScreen from 'lib/common/mediaQueries/useIsMediumScreen';

import SupportFooter from '../SupportFooter';

import './error.scss';

export const buttonAction = createEnum('RELOAD', 'HOME');

type ErrorProps = {
  text?: string | ReactNode;
  title?: string;
  icon?: IconDefinition;
  relative?: boolean;
  hidePrimaryAction?: boolean;
  hideFooter?: boolean;
  buttonProps?: {
    icon?: IconDefinition;
    text?: string;
    action?: keyof typeof buttonAction;
    onClick?: () => void;
  };
};

function Error({ text, icon, title, relative, hidePrimaryAction, hideFooter, buttonProps }: ErrorProps) {
  // Error can be used inside or outside the layout context, so this hook will only work if this is a child of that context
  const { isSoftphone: isSoftphoneLayout } = useLayout();
  const isMediumScreen = useIsMediumScreen();

  // Fallback to the plain softphone media query in case we're rendered from the error boundary. ! This will ignore softphone forced mode !
  const isSoftphoneSize = useIsSoftphoneQuery();

  const history = useHistory();

  const isSoftphone = isSoftphoneLayout || isSoftphoneSize;

  const onClick = () => {
    return match({ buttonAction: buttonProps?.action, onClickAction: buttonProps?.onClick })
      .with({ onClickAction: P.not(undefined) }, () => buttonProps?.onClick?.())
      .with({ buttonAction: undefined }, () => window.location.reload())
      .with({ buttonAction: buttonAction.RELOAD }, () => window.location.reload())
      .with({ buttonAction: buttonAction.HOME }, () => history.push('/'))
      .exhaustive();
  };

  const getIconSize = () => {
    return match({ isSoftphone, isMediumScreen })
      .with({ isSoftphone: true }, () => 75)
      .with({ isMediumScreen: true }, () => 100)
      .with({ isMediumScreen: false }, () => 150)
      .exhaustive();
  };

  return (
    <>
      <div className={cx('error', { 'error--relative': relative })} data-testid="error-page">
        <div
          className={cx('error__page', {
            'error__page--softphone': isSoftphone,
            'error__page--medium-screen': isMediumScreen
          })}
        >
          <div className={cx('error__content', { 'error__content--softphone': isSoftphone })}>
            <Text type="heading1">{title || 'Oops,'}</Text>
            <Text className={cx('error__content__text', { 'error__content__text--softphone': isSoftphone })}>
              {text || 'Something went wrong. Try reloading the page.'}
            </Text>
            {!hidePrimaryAction && (
              <Button icon={buttonProps?.icon || faArrowsRotate} onClick={onClick}>
                {buttonProps?.text || 'Reload'}
              </Button>
            )}
          </div>
          <Icon
            onlyConstrain={IconSizeConstraints.HEIGHT}
            icon={icon || faRobot}
            size={getIconSize()}
            color="primary"
          />
        </div>
        <SupportFooter hidden={hideFooter} className="mb-20" />
      </div>
    </>
  );
}

export default Error;
