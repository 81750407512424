import {
  faClockNine as fadClockNine,
  faCookie as fadCookie,
  faHeadset as fadHeadset,
  faKey as fadKey,
  faMessage as fadMessage,
  faScreenUsers as fadScreenUsers,
  faShield as fadShield,
  faSmile as fadSmile
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faClockNine as farClockNine,
  faCookie as farCookie,
  faHeadset as farHeadset,
  faKey as farKey,
  faMessage as farMessage,
  faScreenUsers as farScreenUsers,
  faShield as farShield,
  faSmile as farSmile
} from '@fortawesome/pro-regular-svg-icons';

import Text from 'lib/common/components/Text';

import { HELP_LINKS } from 'lib/common/constants/help';

export const AUTH_ERROR_HELP_ITEMS = (config) => [
  {
    icon: fadSmile,
    smallIcon: farSmile,
    title: 'Check Your Sign In',
    text: (
      <>
        Make sure you’re signed into Amazon Connect with the correct account. If no email is shown above, your admin may
        not have set you up yet.
      </>
    )
  },
  {
    icon: fadHeadset,
    smallIcon: farHeadset,
    title: 'Check Your Contact Centre',
    text: (
      <>Confirm your user access and permissions for this contact centre. Ensure the web address above is correct.</>
    )
  },
  {
    icon: fadCookie,
    smallIcon: farCookie,
    title: 'Clear Data & Enable Cookies',
    text: (
      <>
        Clear site data and enable cookies for {window.location.host}.{' '}
        <Text type="link" href={`${config.BRAND.helpDocsUrl}${HELP_LINKS.REQUIREMENTS.STORAGE_ACCESS}`}>
          Learn More.
        </Text>
      </>
    )
  },
  {
    icon: fadMessage,
    smallIcon: farMessage,
    title: 'Get In Touch',
    text: <>Contact your Admin for further assistance.</>
  }
];

export const DEVICE_FORGOTTEN_HELP_ITEMS = [
  {
    icon: fadShield,
    smallIcon: farShield,
    title: 'Verification Code',
    text: (
      <>
        When you sign back in you’ll need to <Text.Bold>enter a verification code</Text.Bold>.
      </>
    )
  },
  {
    icon: fadKey,
    smallIcon: farKey,
    title: 'Staying Signed In',
    text: (
      <>
        If you prefer not to enter a verification code each time, simply <Text.Bold>don’t select</Text.Bold> “Forget My
        Device” during sign out. You’ll stay signed in on this device for future logins.
      </>
    )
  }
];

export const SIGNED_OUT_HELP_ITEMS = [
  {
    icon: fadShield,
    smallIcon: farShield,
    title: 'Verification Code',
    text: (
      <>
        You’ll need to sign in to Amazon Connect at the start of your next session, but no verification code is required
        <Text.Bold> on this device</Text.Bold>.
      </>
    )
  },
  {
    icon: fadClockNine,
    smallIcon: farClockNine,
    title: 'Starting Your Next Session',
    text: 'You can click the button below or close this page and re-open the app at any time.'
  },
  {
    icon: fadScreenUsers,
    smallIcon: farScreenUsers,
    title: 'Shared Device',
    text: 'We recommend you forget this device if you share it with other people.'
  }
];
