import { faClock } from '@fortawesome/pro-duotone-svg-icons';

import Loader from 'lib/common/components/Loader';

import IInteractionHistory from 'lib/common/types/InteractionHistory';

import ContentPanel from '../ContentPanel';
import InteractionHistoryTable from './InteractionHistoryTable';

interface IHistory {
  profileHistory?: IInteractionHistory[];
  className?: string;
  currentContactIsViewed: boolean;
  emptyMessage: string;
}

const History = ({ profileHistory, className, currentContactIsViewed, emptyMessage }: IHistory) => {
  return (
    <ContentPanel
      className={className}
      title="Customer Activity"
      subtitle="Activity for this profile may take up to a few hours to appear"
      isEmpty={profileHistory && !profileHistory.length}
      emptyMessage={emptyMessage}
      emptyIcon={faClock}
    >
      {!profileHistory && <Loader small relative />}
      {profileHistory?.length && (
        <InteractionHistoryTable currentContactIsViewed={currentContactIsViewed} interactionHistory={profileHistory} />
      )}
    </ContentPanel>
  );
};

export default History;
