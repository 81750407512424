import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';

import Icon from '../Icon';

import styles from './icon-constellation.module.scss';

const IconConstellation = ({
  leftIcon,
  topIcon,
  rightIcon,
  centerIcon
}: {
  leftIcon: IconDefinition;
  topIcon: IconDefinition;
  rightIcon: IconDefinition;
  centerIcon: IconDefinition;
}) => (
  <div className={styles['icon-constellation__top-container']}>
    <div className={styles['icon-constellation__top-container__inner-container']}>
      <Icon className={styles['icon-constellation__icon-left']} color="primary" icon={leftIcon} />
      <Icon className={styles['icon-constellation__icon-top']} color="primary" icon={topIcon} />
      <Icon className={styles['icon-constellation__icon-right']} color="primary" icon={rightIcon} />
    </div>
    <Icon className={styles['icon-constellation__icon-center']} color="primary" icon={centerIcon} />
  </div>
);

export default IconConstellation;
