import { useState } from 'react';

import { faArrowRight, faCopy, faEnvelope, faPaste } from '@fortawesome/pro-duotone-svg-icons';
import {
  faCircleQuestion as farCircleQuestion,
  faCommentDots as farCommentDots,
  faEnvelope as farEnvelope
} from '@fortawesome/pro-regular-svg-icons';
import { faMessageDots } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import { match } from 'ts-pattern';

import useIsSoftphoneQuery from 'lib/common/hooks/useIsSoftphoneQuery';

import Badge from 'lib/common/components/Badge';
import Button from 'lib/common/components/Button';
import Icon from 'lib/common/components/Icon';
import { InfoCardGroup, InfoCardGroupGap } from 'lib/common/components/InfoCard';
import Text from 'lib/common/components/Text';

import useIsMediumScreen from 'lib/common/mediaQueries/useIsMediumScreen';

import { MfaDestination, MfaDestinations } from '../../../types/AuthState';

import styles from './select-mfa.module.scss';

const Main = ({
  mfaDestinations,
  selectMfaDestination,
  openInfo
}: {
  mfaDestinations: { obfuscatedEmail: string; obfuscatedPhone: string };
  selectMfaDestination: (dest: 'email' | 'sms') => Promise<void>;
  openInfo: () => void;
}) => {
  const isSoftphone = useIsSoftphoneQuery();
  const isMediumScreen = useIsMediumScreen();

  const [loading, setLoading] = useState(false);
  const [mfaSelection, setMfaSelection] = useState<MfaDestination>(MfaDestinations.email);

  return (
    <div className={cx(styles['select-mfa'], { [styles['select-mfa--softphone']]: isSoftphone })}>
      <Text type="heading1">Just Confirming It's You</Text>
      <Text>
        We'll{' '}
        {match(mfaSelection)
          .with(MfaDestinations.email, () => 'email')
          .with(MfaDestinations.sms, () => 'send')
          .otherwise(() => 'sent')}{' '}
        a verification code to{' '}
        <Badge
          type="SECONDARY"
          label={match(mfaSelection)
            .with(MfaDestinations.email, () => mfaDestinations.obfuscatedEmail)
            .with(MfaDestinations.sms, () => mfaDestinations.obfuscatedPhone)
            .exhaustive()}
        />
        . You can enter it on the next page.
      </Text>

      <div className={styles['select-mfa__buttons']}>
        {(isSoftphone || isMediumScreen) && (
          <Button styleType="SECONDARY" icon={farCircleQuestion} onClick={openInfo}>
            How It Works
          </Button>
        )}
        <Button
          busy={loading}
          onClick={() => {
            setLoading(true);
            selectMfaDestination(mfaSelection);
          }}
          icon={match(mfaSelection)
            .with(MfaDestinations.email, () => farEnvelope)
            .with(MfaDestinations.sms, () => farCommentDots)
            .exhaustive()}
        >
          Send
        </Button>
        <div className={styles['select-mfa__link-box']}>
          <Text
            type="link"
            onClick={() => {
              setLoading(false);
              setMfaSelection(
                match(mfaSelection)
                  .with(MfaDestinations.email, () => MfaDestinations.sms)
                  .with(MfaDestinations.sms, () => MfaDestinations.email)
                  .exhaustive()
              );
            }}
          >
            Try another way
          </Text>
        </div>
      </div>
    </div>
  );
};

const Info = () => {
  const isSoftphone = useIsSoftphoneQuery();
  const isMediumScreen = useIsMediumScreen();

  return (
    <InfoCardGroup
      alternating={!(isSoftphone || isMediumScreen)}
      gap={isSoftphone || isMediumScreen ? InfoCardGroupGap.MEDIUM : InfoCardGroupGap.LARGE}
      minimal={isSoftphone}
      className={styles['select-mfa__cards']}
      cards={[
        {
          icon: faEnvelope,
          text: (
            <Text>
              You'll recieve a <Text.Bold inline>verification code</Text.Bold> <Icon icon={faMessageDots} size={15} />{' '}
              in your <Text.Bold>email account</Text.Bold> from no-reply@cw-neon.com.{' '}
              <Text type="link" inline>
                Learn more
              </Text>
              .
            </Text>
          )
        },
        {
          icon: faCopy,
          text: (
            <Text>
              <Text.Bold inline>Copy</Text.Bold> or make a note of your verification code{' '}
              <Icon icon={faMessageDots} size={15} />.
            </Text>
          )
        },
        {
          icon: faPaste,
          text: (
            <Text>
              <Text.Bold inline>Paste</Text.Bold> or type your code in the boxes provided.
            </Text>
          )
        },
        {
          icon: faArrowRight,
          text: (
            <Text>
              Click <Text.Bold inline>Continue</Text.Bold> and we'll finish signing you in.
            </Text>
          )
        }
      ]}
    />
  );
};

export default { Main, Info };
