import { match } from 'ts-pattern';

import { isLocalhost } from './isLocalHost';

const TENANTS_FOR_LOCAL = {
  dev: 'forge',
  sandbox: 'blake',
  staging: 'stagingrem'
} as const;

export const getTenantId = () => {
  const hostname = window.location.hostname;

  const DEPLOYMENT_ENVIRONMENT = process.env.VITE_DEPLOYMENT_ENVIRONMENT!;

  if (isLocalhost()) {
    return match(DEPLOYMENT_ENVIRONMENT)
      .with('dev', 'sandbox', 'staging', (env) => TENANTS_FOR_LOCAL[env])
      .otherwise(() => 'invalid-environment');
  }

  return hostname.split('.')?.[0];
};
