import { useEffect, useState } from 'react';

import { useStreamsContext } from 'lib/core/context/StreamsProvider';

import connectGetter from 'lib/common/utils/connectGetter';

import Context from './Context';
import registerAgentHandlers from './utils/registerAgentHandlers';

export default function AgentProvider({ children }) {
  const { initialized } = useStreamsContext();
  const [agent, setAgent] = useState<connect.Agent | null>(null);
  const [agentConfig, setAgentConfig] = useState<connect.AgentConfiguration | undefined>();

  useEffect(() => {
    if (!initialized) {
      return;
    }

    connect.agent((agent) => {
      const config = connectGetter(agent, 'getConfiguration');
      setAgentConfig(config);
      setAgent(agent);
    });
  }, [initialized]);

  useEffect(() => {
    if (!agent) {
      return;
    }

    registerAgentHandlers(agent);
  }, [agent]);

  return (
    <Context.Provider
      value={{
        agent,
        agentConfig,
        routingProfile: connectGetter(agent, 'getConfiguration')?.routingProfile?.routingProfileId?.split('/')[3],
        states: connectGetter(agent, 'getAgentStates') || [],
        channelConcurrencyMap: connectGetter(agent, 'getConfiguration')?.routingProfile?.channelConcurrencyMap
      }}
    >
      {children}
    </Context.Provider>
  );
}
