import { createContext, useContext } from 'react';

export type TStreamsContext = {
  initializeCCP: () => Promise<void>;
  initialized: boolean;
  failed: boolean;
};

const defaultAuth: TStreamsContext = {
  initializeCCP: async () => {},
  initialized: false,
  failed: false
};

const context = createContext<TStreamsContext>(defaultAuth);

export const useStreamsContext = () => useContext(context);

export default context;
